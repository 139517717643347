<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="housing_room_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('housing_rooms')">
                            <HouseRoomSelectbox
                                v-model="formData.housing_room_id"
                                :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('name')">
                            <b-form-input  v-model="formData.name">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>

                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="type" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('type')">
                            <parameter-selectbox v-model="formData.type"
                                                 :validate-error="errors[0]"
                                                 code="housing_bed_types"
                            ></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
<!--                    <ValidationProvider name="floor" rules="required" v-slot="{ valid, errors }">-->
<!--                        <b-form-group :label="$t('fee')">-->
<!--                            <b-form-input  v-model="formData.fee">-->
<!--                            </b-form-input>-->
<!--                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>-->

<!--                        </b-form-group>-->
<!--                    </ValidationProvider>-->

                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import HousingRoom from "@/services/HousingRoomServices";
import HouseRoomSelectbox from "@/components/interactive-fields/HouseRoomSelectbox.vue";
import HousingBedServices from "@/services/HousingBedServices";
export default {
    components: {
        HouseRoomSelectbox,
        ValidationProvider,
        ValidationObserver,

    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formData: {
                housing_room_id:null,
                name:null,
                type:null,
                fee:null
            },
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            HousingBedServices.show(id)
                .then(response => {
                    let data = response.data.data;
                    this.formData = {
                        housing_room_id:data.housing_room_id,
                        name:data.name,
                        type:data.type,
                        fee:data.fee
                    }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                HousingBedServices.update(this.formId, this.formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>
